.search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    font-size: 2.4rem;
    margin-top: 3rem;
}

.searchBar {
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3.5rem;
}

.loginContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.searchBtn,
.loginWithSpotifyBtn {
    width: 10rem;
    height: 5rem;
    border-radius: 3rem;
    background-color: #005e2b;
    color: white;
}