/* global.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  background-color: #1C1C1C;
  color: #fff;
}