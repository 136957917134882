.track {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    min-width: 2rem;
    height: 2rem;
}

.trackInfo {
    flex: 1;
}